import { RouteName } from '../routes/routeName';

export function useReset() {
  const router = useRouter();
  const route = useRoute();
  const cartStore = useCartStoreV2();
  const webhookStore = useWebhookStoreV2();
  const orderTypeStore = useOrderTypeStore();
  const couponStore = useCouponStore();
  const policiesStore = useLegalPolicies();
  const modalStore = useModalStore();
  const tipsController = useTipsController();
  const currencyController = useCurrencyController();
  const tableController = useTableController();
  const customizeItemStore = useCustomizeItemStore();
  const customerController = useCustomerStore();

  const kioskStatusStore = useKioskStatusStore();
  const kioskSetup = useKioskSetup();

  function flush() {
    cartStore?.clearCart?.();
    webhookStore?.restartWebhook?.();
    orderTypeStore?.clearOrderType?.();
    couponStore?.clearCoupon?.();
    couponStore?.reset?.();
    policiesStore?.resetPolicies?.();
    tableController?.clear();
    modalStore?.closeModal();
    tipsController.clear();
    currencyController.clear();
    customerController.clear();

    customizeItemStore.finishCustomization();
    customizeItemStore.$dispose();
  }

  async function flushState() {
    console.warn('Reset from:', route.name);
    flush();

    router.replace({ name: RouteName.WELCOME });
    // If the WL update the kiosk metadata, call again when end order
    if (kioskStatusStore.updateMetadata) {
      await kioskSetup.setupMetadata();
    }
  }

  return {
    flushState,
  };
}
