<script lang="ts" setup>
import { useEventListener } from '@vueuse/core';

const inputRef = ref<HTMLInputElement | null>(null);

const router = useRouter();
const cartStore = useCartStoreV2();
const couponStore = useCouponStore();

const { getFormatPrice } = useTotal();

const optionsLength = computed(() => Number(couponStore.showTextInput) + Number(couponStore.showQrScanner));

function goCart() {
  router.push({ name: RouteName.CART });
}

function goToValidation() {
  couponStore.validatePromoCode();
  router.push({ name: RouteName.COUPON_VALIDATION });
}

// SCAN QR CODE
/**
 * The Qr works as a keyboard, so we need to listen to the keyup event to add the key to the promo code
 */
if (couponStore.showQrScanner) {
  useEventListener('keyup', (event) => {
    if (event.key === 'Enter') {
      goToValidation();
      return;
    }
    couponStore.promoCode += event.key;
  });
}

</script>

<template>
  <section class="flex flex-col justify-between full-hd">
    <input ref="inputRef" class="hidden" v-model="couponStore.promoCode" />

    <body>
      <div class="flex items-center flex-grow">
        <div class="preview-container">
          <CouponInput v-model="couponStore.promoCode" />

          <div
            class="options-container"
            :class="{
              'opacity-50': couponStore.promoCode.length > 0,
            }"
          >
            <CouponOption
              v-if="couponStore.showTextInput"
              :show-option-lbl="optionsLength > 1"
              :title="`${$t('COUPON.OPTION')} A`"
              :subtitle="$t('COUPON.REDEEM_MANUALLY')"
              :description="$t('COUPON.WRITE_KEYBOARD')"
              icon="icon-edit"
            />
            <div v-if="optionsLength > 1" class="h-64 divider divider-horizontal" />

            <CouponOption
              v-if="couponStore.showQrScanner"
              :show-option-lbl="optionsLength > 1"
              :title="`${$t('COUPON.OPTION')} B`"
              :subtitle="$t('COUPON.REDEEM_QR')"
              :description="$t('COUPON.SCAN_QR')"
              icon="icon-qr"
            />
          </div>
        </div>
      </div>

      <CustomKeyboard
        v-if="inputRef && couponStore.showTextInput"
        v-model="couponStore.promoCode"
        :inserted-keyboard="true"
        :show-email-suggestions="false"
        :input-ref="inputRef"
        :action-lbl="$t('COUPON.VALIDATE')"
        @accept="goToValidation()"
      />
    </body>

    <div class="h-28">
      <FooterActionsCoupon
        :price="getFormatPrice(cartStore.subtotal)"
        :disabled="cartStore.subtotal === 0"
        :action-lbl="$t('CART')"
        @next="goCart()"
        @back="router.back()"
      />
    </div>
  </section>
</template>

<style scoped>
body {
  @apply flex flex-col items-center bg-gradient-to-b h-full from-white to-gray-50 justify-between;
}

.preview-container {
  @apply flex flex-col items-center px-48 h-[700px] relative;
}

.options-container {
  @apply absolute bottom-0 z-10 flex items-start gap-10 text-2xl transition-opacity;
}
</style>
