import { RouteName } from '@common/routes/routeName';
import { RouteRecordRaw } from 'vue-router';
import CouponView from './views/CouponView.vue';

export const couponRoutes: RouteRecordRaw[] = [
  {
    path: 'coupons',
    name: RouteName.COUPON,
    component: CouponView,
  },
  {
    path: 'coupon/validation',
    name: RouteName.COUPON_VALIDATION,
    component: () => import('./views/CouponValidation.vue'),
  },
  {
    path: 'coupon/active',
    name: RouteName.COUPON_ACTIVE,
    component: () => import('./views/CouponValidation.vue'),
  },
];
