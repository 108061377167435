<script setup lang="ts">
import { Validation } from '@vuelidate/core';
import { checkFormKeyError, showFormKeyError } from '@common/utils';
import { Languages } from '@slabcode/kiosks-core/enums';
import { Customer } from '../interfaces/customer';
import { CustomerItem } from '../interfaces/customerItem';

const props = defineProps({
  showClientType: {
    type: Boolean,
    required: false,
    default: false,
  },
  userFields: {
    type: Object as PropType<Map<keyof Customer, CustomerItem>>,
    required: true,
  },
  showAnimationSlide: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['confirm']);

const { locale } = useI18n();
const showKeyboard = ref(false);
const showBackActions = ref(true);
const customerController = useCustomerStore();
const { customerConfig, customer } = storeToRefs(customerController);
const inputRef = ref<HTMLInputElement | null>(null);
const currentItem = ref<keyof Customer>();
const { userFields, showClientType } = toRefs(props);

const model = defineModel({
  required: true,
  type: Object as PropType<Customer>,
});

const vuelidate = defineModel('vuelidate', {
  required: true,
  type: Object as PropType<Validation>,
});

const allowEmailKeyboard = computed(() => currentItem.value === 'email');

const isNumberKeyboard = computed(
  () => currentItem.value === 'phone' || currentItem.value === 'taxIdentificationNumber',
);

const activeInputIndex = ref(-1);
const activeInputsRefs = ref<HTMLCollectionOf<HTMLInputElement>>();
const activeInput = computed(() => activeInputsRefs.value?.[activeInputIndex.value]);

/** Auto focus first invalid field or empty field */
const autoFocusFirstInput = () => {
  const firstInvalidField = Array.from(userFields.value.keys()).find((key) => {
    const fieldValue = model.value[key];
    const isInvalid = vuelidate.value[key].$invalid;
    return !fieldValue || isInvalid;
  });

  if (firstInvalidField) {
    const inputs = document.getElementsByClassName('item-input');
    const index = Array.from(userFields.value.keys()).indexOf(firstInvalidField);

    nextTick(() => {
      activeInputIndex.value = index;
      showKeyboard.value = true;
      (inputs[index] as HTMLInputElement)?.focus();
    });
  }
};

/**
 * close current keyboard and emits 'confirm' event if it's necessary.
 */
function closeKeyBoard() {
  showKeyboard.value = false;
  if (userFields.value.size === 1) emit('confirm');
}

/**
 * Set value to 'currentItem' ref and allow
 * keyboard use to user.
 * @param {keyof Customer} key - Customer key
 */
function setCurrentField(event: Event, key: keyof Customer) {
  inputRef.value = event.target as HTMLInputElement;
  currentItem.value = key;

  if (activeInputsRefs.value) {
    const inputsArray = Array.from(activeInputsRefs.value);
    const index = inputsArray.findIndex((input) => input === event.target);
    activeInputIndex.value = index;
  }

  showKeyboard.value = true;
}

/**
 * Accept current input and focus next input if it's necessary.
 */
function accept() {
  const nextInput = Array.from(activeInputsRefs.value || [])
    .slice(activeInputIndex.value + 1)
    .find((input) => {
      const key = input.id as keyof Customer;
      return !model.value[key] || vuelidate.value[key].$invalid;
    });
  if (nextInput) {
    showKeyboard.value = false;
    activeInputIndex.value = Array.from(activeInputsRefs.value || []).indexOf(nextInput);
    nextTick(() => {
      showKeyboard.value = true;
      nextInput.focus();
    });
  } else {
    closeKeyBoard();
    activeInput.value?.blur();
  }
}

defineExpose({
  autoFocusFirstInput,
});

watch(
  activeInput,
  (input) => {
    if (!input) {
      closeKeyBoard();
      return;
    }
    input.focus();
  },
  { immediate: true },
);

onMounted(() => {
  activeInputsRefs.value = document.getElementsByClassName(
    'item-input',
  ) as HTMLCollectionOf<HTMLInputElement>;
});
</script>

<template>
  <div class="relative customer-info">
    <div
      v-if="showClientType"
      class="grid items-center justify-center grid-cols-2 gap-4 -mb-6 client-type"
    >
      <template v-for="(clientType, index) in customerConfig" :key="clientType">
        <label for="regular" v-if="clientType.enabled">
          <input
            id="regular"
            type="radio"
            name="regular"
            class="radio checked:bg-kiosk-primary"
            :value="index"
            v-model="model.clientType"
          />
          {{ clientType.label[locale.toUpperCase() as Languages] }}
        </label>
      </template>
    </div>

    <div
      class="custom-input-container"
      v-for="[key, item] in userFields"
      :key="item.label"
    >
      <div class="customer-item">
        <span class="block ml-2 text-6xl icon" :class="`icon-${item.icon}`" />
        <label :for="key">
          <span class="relative pr-5 text-2xl">
            {{ item.label }}
            <span
              v-if="vuelidate[key].required"
              class="absolute right-0 text-4xl text-red-500"
            >
              *
            </span>
          </span>

          <input
            v-model="customer[key]"
            @focus="setCurrentField($event, key)"
            @keyup.enter="closeKeyBoard()"
            :placeholder="item.placeholder"
            :id="key"
            :disabled="false"
            class="item-input"
          />

          <p
            v-if="item.info.length > 0 && showClientType"
            class="flex gap-2 py-3 text-lg italic leading-none label-text-alt text-info"
          >
            <span class="icon icon-info-circle" /> {{ item.info }}
          </p>
        </label>
      </div>
      <div v-if="checkFormKeyError(vuelidate, key)" class="label">
        <span class="text-2xl leading-none label-text-alt text-error">{{
          showFormKeyError(vuelidate, key)
        }}</span>
      </div>
    </div>

    <LegalPolicies />
  </div>

  <!-- User keyboard -->
  <SlideTransition
    v-if="showAnimationSlide"
    mode="out-in"
    appear
    :key="showAnimationSlide.toString()"
    class="absolute bottom-0 left-0 w-full"
    @before-enter="showBackActions = false"
    @after-leave="showBackActions = true"
  >
    <template v-if="showKeyboard">
      <!-- Aquí se muestran NumbersKeyboard o CustomKeyboard -->
      <NumbersKeyboard
        v-if="isNumberKeyboard"
        v-model="vuelidate[currentItem!].$model"
        :input-ref="inputRef"
        :max-length="20"
        @close="closeKeyBoard()"
        @confirm="accept()"
      />

      <CustomKeyboard
        v-else
        v-model="vuelidate[currentItem!].$model"
        :show-email-suggestions="allowEmailKeyboard"
        :input-ref="inputRef"
        @close="closeKeyBoard()"
        @accept="accept()"
      />
    </template>
  </SlideTransition>
</template>

<style scoped>
.custom-input-container {
  @apply flex flex-col justify-center gap-2 w-[556px] bg-white;
}

.customer-info {
  @apply flex flex-col items-center justify-center gap-10 py-2 p-8;
}

.customer-item {
  @apply shadow-xl flex justify-center items-center gap-8 px-5 py-3 w-full;
}

.item-input {
  @apply h-14 w-96 bg-neutral-200 placeholder:text-neutral-300 text-[32px] font-medium leading-[101px] tracking-[-1.263px] px-4 focus:outline-none;
}

.client-type label {
  @apply mx-5 flex items-center text-2xl;
}

.client-type input {
  @apply mr-4;
}
</style>
