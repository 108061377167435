<script lang="ts" setup>
import { useItemActionEvent } from '../composables/useItemActionEvent';

const instance = getCurrentInstance();
const {
  item: currentItem,
  filteredModifiers,
  pushCustomization,
  saveCustomization,
  cancelCustomization,
  getModifierClass,
  checkUpgrade,
  acceptUpgrade,
  shouldDisplayUpgrade,
  finishCustomization,
  filterItemsWithAlcohol,
  rejectUpgrade,
} = useCustomize(instance?.proxy);

const {
  selectItem,
  increaseItemQuantity,
  decreaseItemQuantity,
  enableAlcohol,
  disableAlcohol,
  shouldDisplayAlcoholInput,
  showMaximumAnimation,
} = useItemActionEvent(instance?.proxy);

const router = useRouter();
const metadataStore = useMetadataStoreV2();
const { priceDecimals } = storeToRefs(metadataStore);
const customizeItemStore = useCustomizeItemStore();
const { modifierStack, upgradeModifier, isEditing } = storeToRefs(customizeItemStore);
const { t } = useI18n();
const menu = useMenuStore();
const { productsCurrency } = storeToRefs(menu);
const { triggerGoBack } = useGTMEventsComposableV2();
const route = useRoute();

const collapseArray = reactive(
  filteredModifiers.map((_, index) => ({ collapse: index > 0 })),
);

function formatPrice(total: number) {
  const result = isEditing.value ? t('SAVE') : t('ADD');
  const price = formatCurrency(
    productsCurrency.value,
    total,
    priceDecimals.value,
  );
  return `${result} | ${price}`;
}

function onModifierCollapse(index: number, event: boolean) {
  collapseArray[index].collapse = event;
}

function currentIndexModifier() {
  return collapseArray.findIndex((item) => !item.collapse);
}

function onNextClick() {
  const index = currentIndexModifier();
  collapseArray[index].collapse = true;
  const nextIndex = index + 1;
  if (collapseArray[nextIndex]) {
    collapseArray[nextIndex].collapse = false;
  }
}

function onSkipClick() {
  const index = currentIndexModifier();

  collapseArray[index].collapse = true;
  const nextIndex = index + 1;
  if (collapseArray[nextIndex]) {
    collapseArray[nextIndex].collapse = false;
  }
}

function submit() {
  finishCustomization();
}

function nextHandler(): void {
  if (checkUpgrade()) {
    return;
  }

  submit();
}

function prevHandler(): void {
  router.go(-1);
  triggerGoBack(route.name);
}

function dismissUpgrade() {
  rejectUpgrade();
  submit();
}

function confirmUpgrade() {
  acceptUpgrade();
  submit();
}
</script>

<template>
  <section v-if="currentItem" class="bg-white customization full-hd">
    <CustomizeItemHeader :item="currentItem" />

    <div class="mt-0 customization-list">
      <CollapsableContainer
        v-for="(modifier, index) in filteredModifiers"
        :key="modifier.modifierMeta.id"
        :id="`item-${index}`"
        :position="index"
        :title="modifier.modifierMeta.name"
        :model-value="collapseArray[index].collapse"
        @update:model-value="onModifierCollapse(index, $event)"
      >
        <template #breadcrumbs>
          <OrderModifierCounterV2
            v-model="showMaximumAnimation"
            :min-selections="modifier.dynamicMinimumSelections"
            :current-selections="
              customizeItemStore.modifierSelections(modifier)
            "
            :max-selections="modifier.dynamicMaximumSelections"
          />
        </template>

        <div class="modifier-container">
          <template
            v-for="item in filterItemsWithAlcohol(modifier.items)"
            :key="item.itemMeta.id"
          >
            <GridItem
              :item="item"
              :container-class="getModifierClass(item, true)"
              :modifier-decimals="priceDecimals"
              @select="selectItem"
              @customize="pushCustomization"
              @add="increaseItemQuantity"
              @sub="decreaseItemQuantity"
              :display-checkbox="
                modifier.dynamicMaxPerModifierSelectionQuantity === 1
                  && modifier.dynamicMaximumSelections > 1
              "
            />
          </template>
        </div>

        <div
          v-if="index + 1 < (filteredModifiers.length || 0)"
          class="flex items-center justify-end w-full gap-5 px-12"
        >
          <KioskButton
            v-if="!modifier.isRequired"
            text-size="small"
            @on-click="onSkipClick"
          >
            {{ $t("SKIP") }}
          </KioskButton>

          <KioskButton
            text-size="small"
            color="primary"
            class="animate__animated animate__headShake shake"
            :disabled="!modifier.valid"
            @on-click="onNextClick"
          >
            {{ $t("NEXT") }}
          </KioskButton>
        </div>
      </CollapsableContainer>
    </div>

    <div class="border-t border-gray-500">
      <CartActions
        :disabled="
          !currentItem.valid
            || currentIndexModifier() < filteredModifiers.length - 1
        "
        :action-lbl="formatPrice(currentItem?.total || 0)"
        :show-cart-counter="false"
        @back="prevHandler()"
        @next="nextHandler()"
      />
    </div>

    <ConfirmModal
      v-if="shouldDisplayUpgrade && upgradeModifier"
      :icon="upgradeModifier.items[0].itemMeta.photoIds[0]"
      :title="upgradeModifier.items[0].itemMeta.name"
      :message="upgradeModifier.items[0].itemMeta.description"
      :local-modal="true"
      :success-button-label="$t('ORDER.ACCEPT_UPGRADE')"
      :deny-button-label="$t('ORDER.DENY_UPGRADE')"
      @on-close="dismissUpgrade"
      @on-ok="confirmUpgrade"
    />

    <ModifierDialog
      v-for="modElement in modifierStack"
      :key="modElement.item.itemMeta.id"
      :parent-item="modElement.item!"
      @save="saveCustomization"
      @cancel="cancelCustomization"
    />

    <AlcoholConfirm
      v-if="shouldDisplayAlcoholInput"
      modifiers
      @cancel="disableAlcohol"
      @confirm="enableAlcohol"
    />
  </section>
</template>

<style scoped>
.customization {
  @apply flex flex-col overflow-hidden;
}

.shake {
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.customization-button {
  @apply flex gap-1 items-center justify-center w-[308px] !h-16 py-3 text-3xl;
}

.modifier-container {
  @apply py-2 px-12 grid grid-cols-3 gap-8 place-content-start justify-items-center mb-5;
}
</style>
