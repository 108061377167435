import { HubsterOrderItem } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order/item';
import { CouponResponse,
  CouponResponseInfo,
  ProductCoupon } from '../interfaces';
import { CardInfo } from '../interfaces/cardInfo';

export const useProductCouponStore = defineStore('productCoupon', () => {
  const productCoupon = ref<ProductCoupon | null>(null);
  const couponViewInfo = ref<CardInfo | null>(null);

  const { getFormatPrice } = useTotal();

  const originalItemPrice = computed(() =>
    getFormatPrice(productCoupon.value?.originalItemPrice ?? 0));
  const discountPrice = computed(() =>
    getFormatPrice(productCoupon.value?.item.price.amount ?? 0));

  function isCouponProduct(
    couponInfo: CouponResponseInfo['couponInfo'],
  ): couponInfo is ProductCoupon {
    return (couponInfo as ProductCoupon)?.item !== undefined;
  }

  function setup(couponResponse: CouponResponse) {
    if (!couponResponse.contents) return;
    if (!isCouponProduct(couponResponse.contents.couponInfo)) return;
    productCoupon.value = couponResponse.contents.couponInfo;

    couponViewInfo.value = {
      cardDescription: productCoupon.value.item.description || couponResponse.contents.reservedCoupon.description,
      title: couponResponse.message.title,
      description: couponResponse.message.description,
      cardTitle: productCoupon.value.item.name,
      imageUrl: productCoupon.value.item.photoIds[0],
    };
  }

  function reset() {
    productCoupon.value = null;
    couponViewInfo.value = null;
  }

  function getCouponCartItem(): HubsterOrderItem | null {
    if (!productCoupon.value) return null;
    const { item: couponItem } = productCoupon.value;
    return {
      quantity: 1,
      price: couponItem.price.amount,
      id: couponItem.id,
      name: couponItem.name,
    };
  }

  return {
    isCouponProduct,
    originalItemPrice,
    discountPrice,
    productCoupon,
    couponViewInfo,
    setup,
    reset,
    getCouponCartItem,
  };
});
