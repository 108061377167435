export const es = {
  FROM: 'Desde',
  SCAN_QR: 'Escanear QR',
  CLAIM_COUPON: 'Redimir cupón',
  COUPON_QR_DESCRIPTION: 'Acerca el código QR a la luz verde de nuestro Kiosco.',
  CUPON: 'Cupón',
  WRITE_COUPON_CODE: 'Escribe tu código de cupón aquí',
  INSERT_TABLE_NUMBER: 'Ingrese el número de mesa',
  TABLE: 'Mesa',
  SCAN_QR_OR_TABLE_NUMBER: 'Escanea el QR o ingresa el número del localizador',
  NEXT: 'Siguiente',
  BACK: 'Regresar',
  OPTIONAL: 'Opcional',
  DONE: 'Listo',
  SELECT_LANGUAGE: 'Seleccionar idioma',
  SELECT_ORDER_TYPE: '¿Dónde quieres comer?',
  SOMETHING_WENT_WRONG: 'Algo salió mal, por favor inténtalo de nuevo o más tarde',
  INTERNAL_ERROR: 'Fallo interno',
  EAT_HERE: 'Comer aquí',
  RESTART: 'Reiniciar',
  RETRY: 'Reintentar',
  CONTINUE: 'Continuar',
  ACCEPT: 'Aceptar',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  TAKE_AWAY: 'Para llevar',
  PICK_UP_COUNTER: 'Recoger en mostrador',
  CRAVING_SELECTION: '¿Qué se te antoja?',
  TOTAL: 'Total',
  TAXES: 'Impuestos',
  DISCOUNTS: 'Descuentos',
  SUBTOTAL: 'Subtotal',
  ADD: 'Agregar',
  NOT_AVAILABLE: 'No disponible',
  ADD_CART: 'Agregar al carrito',
  CART: 'Carrito',
  SAVE: 'Guardar',
  SAVED: 'Ahorraste',
  CUSTOMIZE: 'Personalizar',
  QUANTITY: 'Cantidad',
  PRICE: 'Precio',
  ITEMS: 'Items',
  SKIP: 'Omitir',
  TYPE_HERE: 'Escribe Aquí',
  ADD_VOUCHER: 'Agregar cupón',
  SCAN_VOUCHER: 'Escanea el código QR',
  ADD_ANOTHER_VOUCHER: 'Agregar otro cupón',
  GO_BACK: 'Volver',
  VOUCHER_REJECTED: 'Cupón rechazado',
  VOUCHER_REJECTED_DETAIL: 'Lo sentimos, hubo un error al escanear el código QR. Por favor, inténtalo de nuevo',
  CASH: 'Efectivo',
  CARD: 'Tarjeta',
  PAY_ON_SITE: 'Paga en caja',
  MAIN_MENU: 'Menú principal',
  MODIFIERS_BACK_TITLE: '¿Deseas volver al Catálogo?',
  MODIFIERS_BACK_DESCRIPTION: 'Perderás el avance en la personalización de este producto',
  CANCEL: 'Cancelar',
  CONTAINS_ALCOHOL: 'Contiene alcohol',
  PAY: 'Pagar',
  TIMELINE: {
    CHOOSE_PRODUCT: 'Seleccionar producto',
    CUSTOMIZATION: 'Personalizar',
    CONFIRM: 'Confirmar',
  },
  ORDER: {
    BACK_MENU: 'Volver al menú',
    BACK_CART: 'Volver al carrito',
    UPGRADE_MODIFIER_QUESTION: '¿Quiere agrandar su combo?',
    ACCEPT_UPGRADE: 'Si',
    DENY_UPGRADE: 'No',
    UPGRADE_MODIFIER_DESC: 'Se va a agrandar el combo',
    UPGRADE_LABEL: 'Combo agrandado',
    EMPTY_CART: '¡Ups! el carrito está vacío',
    EMPTY_CART_DESCRIPTION: 'O selecciona algo delicioso del carrusel para agregar al carrito.',
  },
  CANCEL_ORDER: {
    QUESTION: '¿Desea cancelar esta orden?',
    ADVICE: 'Se perderá el avance que tengas hasta este momento y volverás al inicio',
    CONFIRMATION: 'Si, cancelar',
    CANCEL: 'Regresar',
  },
  DELETE_ITEM: {
    QUESTION: '¿Desea eliminar este producto?',
    ADVICE: 'Usted va a eliminar el producto',
    CONFIRMATION: 'Si, eliminar',
  },
  INACTIVITY: {
    TITLE: '¿Sigues ahí?',
    ADVICE: 'En unos segundos se restaurará la orden y se volverá al inicio',
    QUESTION: '¿Deseas continuar con tu orden?',
  },
  MODIFIERS_MODAL: {
    TITLE: 'Personaliza los ingredientes',
  },
  CHECKOUT: {
    CLIENT_INFO_TITLE: 'Ingresa tu nombre, así te llamaremos cuando tu orden este lista',
    SUMMARY_TITLE: 'Revisa tu resumen de compra y realiza el pago',
    SUMMARY_PAYMENT_MESSAGE: 'Acerca o desliza la tarjeta por el terminal para finalizar tu compra',
    GET_CLOSE_CARD: 'Inserte la tarjeta en la terminal',
    REJECTED_PAY: 'Pago rechazado',
    REJECTED_PAY_AGAIN: 'Pago rechazado de nuevo',
    REJECTED_DESCRIPTION: 'Lamentamos lo ocurrido, por favor <b>dirígete a la caja</b> para hacer tu pedido',
    CASH_PAY: 'Pagar en efectivo',
    PROCESSING: 'Procesando tu orden',
    CANCEL: 'Cancelar orden',
    TABLE: 'Elige como quieres recibir tu orden',
    YOU_CAN_ALSO: 'También puedes',
  },
  SUCCESS_ORDER: {
    TITLE: 'Orden creada con éxito',
    DESCRIPTION: 'Por favor, <b>dirígete a la caja especial del Kiosko</b> y paga ahí tu orden',
    ORDER: 'Número de orden',
    CARD_DESCRIPTION: 'tu pago fue exitoso, <b>disfruta tu orden</b>',
    POS_FAIL: 'Por favor <b>dirígete a la caja</b> con la factura a verificar tu orden',
  },
  SUCCESS_ORDER_CARD: {
    TITLE: 'Pago realizado con éxito',
    DESCRIPTION: 'gracias por tu compra, en unos minutos estará lista tu orden',
    VOUCHER: 'Cupón redimido con éxito',
  },
  USER_INFO: {
    NAME: 'Nombre',
    NAME_NIT: 'Nombre o Razón Social',
    COMPANY_NAME: 'Razón Social',
    EMAIL: 'Correo electrónico',
    DOCUMENT_ID: 'Número de cedula / NIT',
    PHONE: 'Número de teléfono',
    PHONE_PLACEHOLDER: '3101234567',
    EMAIL_PLACEHOLDER: "correo{'@'}correo.com",
    NAME_PLACEHOLDER: 'Nombre Apellido',
    DOCUMENT_PLACEHOLDER: 'Sin dígito de verificación',
    VALIDATIONS: {
      REQUIRED: 'El campo "{field}" es requerido',
      EMAIL: 'El formato del email es inválido',
      ALPHA: 'Solo letras son permitidas',
      BILLING_NEEDED: 'Datos necesarios para emitir factura electrónica',
      BILLING_ID_NEEDED: 'Datos necesarios para emitir factura electrónica y acumular puntos',
      BILLING_NAMES_NEEDED: 'Nombres y Apellidos, o Razón Social son necesarios para emitir factura electrónica',
      MIN_LENGTH: 'El campo "{field}" debe tener como mínimo {length} catacteres',
      MAX_LENGTH: 'El campo "{field}" debe tener como máximo {length} catacteres',
    },
    CLIENT_TYPE: {
      REGULAR: 'Persona natural',
      COMPANY: 'Persona jurídica',
    },
  },
  ALCOHOL: {
    TITLE: 'Atención',
    GALLERY_MESSAGE: 'Este producto contiene alcohol.\nPor favor, confirma que eres mayor de edad antes de proceder con la compra',
    MODIFIERS_MESSAGE: 'Algunas opciones para este producto contienen alcohol.\nPor favor, confirma que eres mayor de edad antes de escoger tus opciones',
    CONFIRM: 'Tengo 18+',
    CANCEL: 'Soy menor',
  },
  SPACE: 'espacio',
  ORDER_HERE: '¡Toca para ordenar!',
  PRODUCTS: {
    OUT_OF_STOCK: 'Agotado',
  },
  LEGAL: {
    TERMS_AND_CONDITIONS: 'Acepto términos y condiciones',
    PRIVACY_POLICY: 'Acepto política de privacidad',
  },
  CURRENCY: {
    MODAL_TITLE: '¿Con qué moneda deseas pagar?',
    MODAL_DESCRIPTION: 'Selecciona una moneda para continuar',
    BACK: 'Volver',
  },
  TIPS: {
    MODAL_TITLE: '¿Te gustaría agradecer a quien preparará tu comida?',
    MODAL_DESCRIPTION: 'Deja una propina y muestra tu aprecio',
    ADD_AND_PAY: 'Agregar y pagar',
    ADD_AND_CONTINUE: 'Agregar y seguir',
    OMIT_AND_PAY: 'Omitir y pagar',
    OMIT_AND_CONTINUE: 'Omitir y seguir',
  },
  STEP: 'Paso',
  SOMETHING_WENT_WRONG_PAGE: {
    TITLE: 'Algo salió mal',
    DESCRIPTION: 'No te preocupes, esto puede suceder de vez en cuando',
    BACK_HOME: 'Volver al inicio',
  },

  COUPON: {
    USE_COUPON: 'Usar cupón',
    NOTE_VALIDATION: 'Recuerda que para mantener tu cupón activo, debes cumplir con las condiciones establecidas',
    COUPON_SECTION: 'Sección de cupones',
    CHOSE: 'Elige como redimir tu cupón',
    REDEEM_MANUALLY: 'Redimir manualmente',
    WRITE_KEYBOARD: 'Escribe el código de tu cupón utilizando el teclado del kiosko',
    REDEEM_QR: 'Redimir con QR',
    SCAN_QR: 'Acerca el código QR al lector, en la esquina inferior izquierda del kiosco',
    VALIDATING: 'Validando cupón',
    TAKE_SECONDS: 'Solo tomara unos segundos',
    DO_YOU_WANT_TO_ADD: '¿Quieres agregar algo más delicioso a tu carrito?',
    CONTINUE_SHOPPING: 'Seguir comprando',
    GO_TO_CART: 'Ir al carrito',
    OTHER_COUPON: 'Otro cupón',
    CLOSE: 'Cerrar',
    VALIDATE: 'Validar',
    OPTION: 'Opción',
    COUPON_NOT_AVAILABLE: 'Este cupón ya no está disponible',
    LOOKS_IS_NOT_AVAILABLE: 'Parece que este cupón ya no está activo. Pero no te preocupes, puedes probar con otro o revisar nuevas promociones disponibles.',
    COUPON_ALREADY_REDEEMED: 'Tu cupón ya se ha redimido',
    IF_YOU_WANT_DELETE_FIRST: 'Si deseas canjear otro cupón, primero tendrás que eliminar el que acabas de aplicar.',
    COUPON_DELETED: 'El cupón ha sido eliminado y se ha quitado de tu carrito',
    DELETED_SUGGESTION: '¿Deseas usar otro cupón o continuar con tu compra?',
    COUPON_COULD_NOT_BE_DELETED: 'El cupón no pudo ser eliminado',
    NOT_DELETED_SUGGESTION: '¿Deseas intentar de nuevo o continuar con tu compra?',
  },

};

export default es;
