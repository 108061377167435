/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import { ArrayElement } from '@common/types/arrayType';

import { rootFactory } from '@lib/factories';

import {
  ItemDescriptor,
  quantifiable
} from '@slabcode/hubster-modifiers-utils';
import { HubsterOrderTotal } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order';

import { v4 as uuidv4 } from 'uuid';

import { ICartItem, ICartState } from '../interfaces/cartState';
import { itemTaxes } from '../utils/itemSubtotal';

const INITIAL_STATE: ICartState = {
  items: [],
};

export const useCartStoreV2 = defineStore('cartV2', {
  state: () => ({ ...INITIAL_STATE }),
  actions: {
    addItem(item: ItemDescriptor, index?: number): ArrayElement<ICartState['items']> {
      const toAdd = {
        item,
        description: itemDescription(item),
        identifier: uuidv4(),
      };
      if (typeof index === 'number') {
        this.items.splice(index, 0, toAdd);
        return toAdd;
      }
      this.items = [...this.items, toAdd];
      return toAdd;
    },
    alreadyAddedItem(itemId: string): ICartItem | null {
      const itemOnCart = this.items.find(
        (_item) => _item.item.itemMeta.id === itemId,
      );
      if (itemOnCart && quantifiable(itemOnCart.item.actions)) {
        itemOnCart.item.actions.increase();
        this.updateCart();
        return itemOnCart;
      }
      return null;
    },
    addItemWithoutCustomization(itemId: string, index?: number): ArrayElement<ICartState['items']> {
      const itemOnCart = this.alreadyAddedItem(itemId);
      if (itemOnCart) return itemOnCart;

      const item = rootFactory({ itemId });
      return this.addItem(item, index);
    },

    /**
     * Adds an item with default modifiers to the cart, this use case is when there is an item
     * that has just one modifier and its not editable because min and max is 1 and is required.
     * @param item - The item descriptor to be added.
     * @param index - The optional index at which the item should be added.
     * @returns The updated array of items in the cart.
     */
    addItemWithDefaultModifiers(item: ItemDescriptor, index?: number): ArrayElement<ICartState['items']> {
      const itemOnCart = this.alreadyAddedItem(item.itemMeta.id);
      if (itemOnCart) return itemOnCart;
      return this.addItem(item, index);
    },
    increaseItemQuantity(item: ItemDescriptor) {
      if (quantifiable(item.actions)) {
        item.actions.increase();
      }
      this.updateCart();
    },
    decreaseItemQuantity(item: ItemDescriptor) {
      if (quantifiable(item.actions)) {
        item.actions.decrease();
      }
      if (item.quantity === 0) {
        const itemIndex = this.items.findIndex((_item) => _item.item === item);
        this.items.splice(itemIndex, 1);
      }
      this.updateCart();
    },
    removeItem(item: ItemDescriptor) {
      const itemIndex = this.items.findIndex((_item) => _item.item === item);
      this.items.splice(itemIndex, 1);
      this.updateCart();
    },
    updateItemDescription(itemIndex: number) {
      const { item } = this.items[itemIndex];
      this.items[itemIndex].description = itemDescription(item);
      this.updateCart();
    },
    updateCart() {
      this.items = [...this.items];
    },
    clearCart() {
      this.items = [];
    },
    orderTotal(): HubsterOrderTotal {
      const tipsController = useTipsController();
      const invoiceCouponStore = useInvoiceCouponStore();

      return {
        subtotal: this.subtotal - this.taxes,
        tax: this.taxes,
        discount: invoiceCouponStore.discount,
        tip: tipsController.calculatedTip,
        total:
          this.subtotal
          - invoiceCouponStore.discount
          + tipsController.calculatedTip,
      };
    },
  },
  getters: {
    itemsCount(): number {
      const productCouponStore = useProductCouponStore();
      return this.items.reduce((total, item) => item.item.quantity + total, 0) + (productCouponStore.productCoupon ? 1 : 0);
    },

    itemsCountWithoutCoupon(): number {
      return this.items.reduce((total, item) => item.item.quantity + total, 0);
    },

    itemCartCount: (state) => (itemId: string) =>
      state.items
        .filter((item) => item.item.itemMeta.id === itemId)
        .reduce((total, item) => item.item.quantity + total, 0),
    subtotal(): number {
      const productCouponStore = useProductCouponStore();
      return (
        this.items.reduce((total, item) => item.item.total + total, 0)
        // If there is a product coupon
        + (productCouponStore.productCoupon?.item.price.amount ?? 0)
      );
    },
    taxes: (state) =>
      state.items.reduce((taxes, item) => taxes + itemTaxes(item.item), 0),
    totalWithTaxes(): number {
      return this.subtotal + this.taxes;
    },
  },
});
