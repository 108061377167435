/* eslint-disable vue/max-len */
export const METADATA_MOCK = {
  // Configuración de la marca: Define los elementos visuales y de interacción, como colores, fuentes y otros estilos.
  brandSettings: {
    colors: {
      primary: '#5d35b0', // Color principal para el diseño del kiosco.
      secondary: '#05a045', // Color secundario para complementar el diseño.
      tertiary: '#a9a9a9', // Color terciario para fondos o detalles menores.
      background: '#a9a9a9', // Color de fondo por defecto.
    },
    fonts: {
      current: 'google', // Tipo de fuente actual que se usa.
      name: 'Allan', // Nombre de la fuente seleccionada.
      source: 'https://fonts.googleapis.com/css2?family=Allan:wght@400;700&display=swap', // URL de la fuente.
    },
    inactivity: {
      timer: 90, // Tiempo en segundos antes de considerar la sesión inactiva.
      screen: 30, // Tiempo en segundos para mostrar la pantalla de inactividad.
    },
    cards: {
      radius: '!rounded-xl', // Configuración del radio de las tarjetas.
    },
    buttons: {
      radius: '!rounded-3xl', // Configuración del radio de los botones.
      colors: {
        ghost: {
          background: '#00000000', // Fondo transparente para botones "ghost".
          text: '#000000', // Color del texto para botones "ghost".
        },
        primary: {
          background: '#5d35b0', // Fondo para los botones principales.
          text: '#fff', // Texto para los botones principales.
        },
      },
      showIcons: true, // Determina si los botones muestran íconos.
      weight: 'font-bold', // Grosor del texto de los botones.
      counterStyle: 'outline', // Estilo del contador en los botones.
    },
    layout: {
      category: '2', // Tipo de disposición del contenido en categorías.
      modifiers: 'steps', // Modificadores principales para el diseño.
      nestedModifiers: 'list', // Modificadores anidados para elementos.
      tableNumber: 'checkoutView',
    },
    languages: ['ES', 'EN'], // Idiomas habilitados.
    legal: {
      termsAndConditions: {
        display: true, // Si se muestran los términos y condiciones.
        url: 'www.google.com', // URL de los términos y condiciones.
        messages: {
          EN: 'do you accept  terms and conditions?', // Mensaje en inglés.
          ES: 'aceptas', // Mensaje en español.
          FR: 'se', // Mensaje en francés.
          PT: 'se', // Mensaje en portugués.
        },
      },
      privacyPolicies: {
        display: true, // Si se muestran las políticas de privacidad.
        url: 'www.prueba.com', // URL de las políticas de privacidad.
        messages: {
          EN: 'do you accept  terms and conditions?', // Mensaje en inglés.
          ES: 'do you accept  terms and conditions?', // Mensaje en español.
          FR: 'do you accept  terms and conditions?', // Mensaje en francés.
          PT: '', // Mensaje en portugués (vacío).
        },
      },
      advertising: {
        display: true, // Si se muestra la aceptación de publicidad.
        url: '', // URL relacionada (vacía).
        messages: {
          EN: 'do you accept  terms and promos?', // Mensaje en inglés.
          ES: 'do you accept  terms and promos?', // Mensaje en español.
          FR: 'do you accept  terms and promos?', // Mensaje en francés.
          PT: '', // Mensaje en portugués (vacío).
        },
      },
    },
  },

  // Configuración del clúster: Define aspectos básicos del cliente, ticket, flujo, moneda, y otros ajustes relacionados con la operación.
  clusterSettings: {
    basics: {
      ticket: {
        splitPaymentSection: false, // Si se permite dividir pagos.
        printRejectTicket: true, // Si se imprime un ticket de rechazo.
      },
      subflows: {
        hasLoyaltyProgram: false, // Si hay un programa de lealtad.
      },
      pinpad: {
        pinpadBrand: 4, // Marca del dispositivo PinPad.
      },
    },
    currency: {
      preferredCurrencyCode: 'COP', // Código de la moneda preferida.
      decimals: true, // Si se manejan decimales en los montos.
      conversions: [
        {
          code: 'USD', // Código de la moneda de conversión.
          rate: 1.2, // Tasa de conversión.
        },
      ],
    },
    tips: {
      enabled: false,
      style: 'gamified', // "gamified" | "simple"
      amounts: {
        low: 1,
        medium: 5,
        high: 10,
        excellent: 20,
      },
    },
    customerInfo: [
      {
        enabled: true,
        label: {
          ES: 'Default ES',
          EN: 'Default EN',
          PT: 'Default PT',
          FR: 'Default FR',
        },
        customerFields: {
          name: {
            enabled: true,
            isMandatory: true,
            placeholder: {
              ES: 'Nombre y apellido',
              EN: 'Name and surname',
              PT: 'Nome e sobrenome',
              FR: 'Nom et prénom',
            },
          },
          phone: {
            enabled: true,
            isMandatory: false,
            placeholder: {
              ES: 'Número de teléfono',
              EN: 'Phone number',
              PT: 'Número de telefone',
              FR: 'Numéro de téléphone',
            },
          },
          email: {
            enabled: true,
            isMandatory: false,
            placeholder: {
              ES: 'correo@ejemplo.com',
              EN: 'email@example.com',
              PT: 'email@exemplo.com',
              FR: 'email@exemple.com',
            },
          },
          idNumber: {
            enabled: true,
            isMandatory: false,
            placeholder: {
              ES: 'Sin dígito de verificación',
              EN: 'No check digit',
              PT: 'Sem dígito de verificação',
              FR: 'Pas de chiffre de contrôle',
            },
          },
        },
      },
      {
        enabled: true,
        label: {
          ES: 'Default 2 ES',
          EN: 'Default 2 EN',
          PT: 'Default 2 PT',
          FR: 'Default 2 FR',
        },
        customerFields: {
          name: {
            enabled: true,
            isMandatory: false,
            placeholder: {
              ES: 'Razón social',
              EN: 'Bussiness name',
              PT: 'Nome e sobrenome',
              FR: 'Nom et prénom',
            },
          },
          phone: {
            enabled: true,
            isMandatory: false,
            placeholder: {
              ES: 'Número de teléfono',
              EN: 'Phone number',
              PT: 'Número de telefone',
              FR: 'Numéro de téléphone',
            },
          },
          email: {
            enabled: false,
            isMandatory: false,
            placeholder: {
              ES: 'correo@ejemplo.com',
              EN: 'email@example.com',
              PT: 'email@exemplo.com',
              FR: 'email@exemple.com',
            },
          },
          idNumber: {
            enabled: false,
            isMandatory: false,
            placeholder: {
              ES: 'Sin dígito de verificación',
              EN: 'No check digit',
              PT: 'Sem dígito de verificação',
              FR: 'Pas de chiffre de contrôle',
            },
          },
        },
      },
    ],
  },

  // Configuración del kiosco: Define ajustes específicos como pagos, cumplimientos, idiomas y opciones relacionadas.
  kioskSettings: {
    options: {
      startButton: true, // Si se muestra el botón de inicio.
      awaitPosResponse: true, // Si espera una respuesta del POS.
    },
    payment: {
      cash: true, // Si se aceptan pagos en efectivo.
      card: false, // Si se aceptan pagos con tarjeta.
    },
    fulfillment: {
      pickUp: {
        enabled: true, // Si la opción de recogida está habilitada.
        settings: {
          pickUpAtCounter: true, // Muestra el botón de recoger en el mostrador.
          table: true, // Si se permite seleccionar mesa.
          qrScanner: true, // Si se habilita el escáner QR.
        },
      },
      dineIn: {
        enabled: true, // Si la opción de comer en el lugar está habilitada.
        settings: {
          pickUpAtCounter: true, // Muestra el botón de recoger en el mostrador.
          table: true, // Si se permite seleccionar mesa.
          qrScanner: true, // Si se habilita el escáner QR.
        },
      },
    },
    coupons: {
      cartView: {
        enabled: true, // Si se aceptan cupones.
        settings: {
          text: true, // Si se pueden ingresar cupones como texto.
          qrScanner: true, // Si se habilita el escáner QR para cupones.
        },
      },
      mainView: {
        enabled: false, // Si se aceptan cupones.
        settings: {
          text: false, // Si se pueden ingresar cupones como texto.
          qrScanner: false, // Si se habilita el escáner QR para cupones.
        },
      },
    },
    languages: {
      default: 'ES', // Idioma predeterminado.
      enabled: ['ES', 'EN'], // Idiomas habilitados.
    },
  },

  // Imágenes personalizadas: URLs para recursos gráficos utilizados en el kiosco.
  customImages: {
    brandImage: '...', // Imagen de la marca.
    invoiceLogo: '...', // Logo en las facturas.
    loader: '...', // Imagen de carga.
    mainFooter: '...', // Imagen del pie de página principal.
    mainHeader: '...', // Imagen del encabezado principal.
    modalFooter: '...', // Imagen del pie de página en modales.
    modalHeader: '...', // Imagen del encabezado en modales.
    pausedBanner: '...', // Banner de pausa.
    standbyBanner: '...', // Banner en espera.
    timelineIcon: '...', // Icono de la línea de tiempo.
    carousel: '...', // GIF para el carrusel.
    termsAndConditions: '...', // Imagen para términos y condiciones.
    privacyPolicies: '...', // Imagen para políticas de privacidad.
    paymentMethods: '...', // Imagen para métodos de pago.
  },

  // Banners publicitarios: URLs de banners multimedia.
  banners: [
    '...', // Banner 1 (imagen o video).
    '...', // Banner 2 (imagen o video).
    // Más banners...
  ],

  // Horarios del kiosco: Define los tiempos de operación del kiosco.
  kioskSchedules: {
    openingTime: '2024/11/18 07:00', // Hora de apertura.
    closingTime: '2024/11/18 22:00', // Hora de cierre.
    reopeningTime: '2024/11/19 07:00', // Hora de reapertura.
  },
};
