<script setup lang="ts">
import { HubsterOrderTotal } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order';
import { Languages } from '@slabcode/kiosks-core/enums';
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { ValidationArgs } from '@vuelidate/core';
import OrderCheckoutUserInfoV2 from '../components/OrderCheckoutUserInfoV2.vue';

const { t, locale } = useI18n();
const router = useRouter();

const cartStoreV2 = useCartStoreV2();
const webhookStore = useWebhookStoreV2();
const customerStore = useCustomerStore();
const { jobResponse, paymentInProgress } = storeToRefs(webhookStore);
const { customer, customerConfig, hasCustomerConfig, showCustomerView } = storeToRefs(
  customerStore,
);

const { triggerGoBack } = useGTMEventsComposableV2();
const legalPolicies = useLegalPolicies();
const tableController = useTableController();
const route = useRoute();
const { canContinue } = storeToRefs(legalPolicies);
const invoiceCouponStore = useInvoiceCouponStore();
const { createOrder } = useCreateOrder();

const hideSummary = ref(false);
const showAnimationSlide = ref(false);
const userInfoComponent = ref<InstanceType<typeof OrderCheckoutUserInfoV2>>();

const collapseSteps = ref<{
  customerInfo?: { collapse: boolean; checked: boolean };
  payment?: { collapse: boolean; checked: boolean };
  tableNumber?: { collapse: boolean; checked: boolean };
}>({});

const currentStepIndex = computed(() =>
  Object.values(collapseSteps.value).findIndex((stp) => stp.collapse === false));

const showClientType = computed(() => customerConfig.value && hasCustomerConfig.value);

const orderTotal = computed(
  (): Partial<HubsterOrderTotal> => {
    const { subtotal, taxes } = cartStoreV2;

    const total = subtotal - invoiceCouponStore.discount;
    const subtotalWithoutTaxes = subtotal - taxes;

    return {
      subtotal: taxes === 0 ? subtotal : subtotalWithoutTaxes,
      tax: taxes,
      discount: invoiceCouponStore.discount,
      total,
    };
  },
);

const activeCustomerConfig = computed(() => {
  if (typeof customer.value.clientType !== 'number') return null;
  if (!customerConfig.value || customerConfig.value.length === 0) return null;
  return customerConfig.value[customer.value.clientType];
});

const userFields = computed(
  (): Map<keyof typeof customer.value, any> => {
    const values = new Map();
    const currentLocale = locale.value.toUpperCase(); // EJ: "ES"
    const config = activeCustomerConfig.value;
    if (!config || !config.customerFields) return values;

    if (config.customerFields.name?.enabled) {
      values.set('name', {
        label: t('USER_INFO.NAME'),
        type: 'text',
        icon: 'account',
        info: t('USER_INFO.VALIDATIONS.BILLING_NAMES_NEEDED'),
        placeholder:
          config.customerFields.name.placeholder?.[currentLocale as Languages] || '',
      });
    }
    if (config.customerFields.idNumber?.enabled) {
      values.set('taxIdentificationNumber', {
        label: t('USER_INFO.DOCUMENT_ID'),
        type: 'number',
        icon: 'citizen-id',
        info: t('USER_INFO.VALIDATIONS.BILLING_ID_NEEDED'),
        placeholder:
          config.customerFields.idNumber.placeholder?.[currentLocale as Languages] || '',
      });
    }
    if (config.customerFields.phone?.enabled) {
      values.set('phone', {
        label: t('USER_INFO.PHONE'),
        type: 'number',
        icon: 'mobile',
        info: '',
        placeholder:
          config.customerFields.phone.placeholder?.[currentLocale as Languages] || '',
      });
    }
    if (config.customerFields.email?.enabled) {
      values.set('email', {
        label: t('USER_INFO.EMAIL'),
        type: 'email',
        icon: 'email',
        info: t('USER_INFO.VALIDATIONS.BILLING_NEEDED'),
        placeholder:
          config.customerFields.email.placeholder?.[currentLocale as Languages] || '',
      });
    }
    return values;
  },
);

const rules = computed(
  (): ValidationArgs => {
    const config = activeCustomerConfig.value?.customerFields;
    return {
      name: {
        ...(config?.name.enabled && config?.name.isMandatory
          ? {
            required: helpers.withMessage(
              t('USER_INFO.VALIDATIONS.REQUIRED', {
                field: showClientType.value
                  ? t('USER_INFO.NAME_NIT')
                  : t('USER_INFO.NAME'),
              }),
              required,
            ),
          }
          : {}),
        minLength: helpers.withMessage(
          t('USER_INFO.VALIDATIONS.MIN_LENGTH', {
            field: t('USER_INFO.NAME'),
            length: 2,
          }),
          minLength(2),
        ),
        isNameValid: helpers.withMessage(
          t('USER_INFO.VALIDATIONS.ALPHA'),
          (value: string) => /^[a-zA-Z\s\u00F1\u00D1]*$/.test(value),
        ),
      },
      email: {
        ...(config?.email.enabled && config?.email.isMandatory
          ? {
            required: helpers.withMessage(
              t('USER_INFO.VALIDATIONS.REQUIRED', { field: t('USER_INFO.EMAIL') }),
              required,
            ),
          }
          : {}),
        email: helpers.withMessage(t('USER_INFO.VALIDATIONS.EMAIL'), email),
        minLength: helpers.withMessage(t('USER_INFO.VALIDATIONS.EMAIL'), email),
      },
      phone: {
        ...(config?.phone.enabled && config?.phone.isMandatory
          ? {
            required: helpers.withMessage(
              t('USER_INFO.VALIDATIONS.REQUIRED', { field: t('USER_INFO.PHONE') }),
              required,
            ),
          }
          : {}),
      },
      taxIdentificationNumber: {
        ...(config?.idNumber.enabled && config?.idNumber.isMandatory
          ? {
            required: helpers.withMessage(
              t('USER_INFO.VALIDATIONS.REQUIRED', {
                field: t('USER_INFO.DOCUMENT_ID'),
              }),
              required,
            ),
          }
          : {}),
        minLength: helpers.withMessage(
          t('USER_INFO.VALIDATIONS.MIN_LENGTH', {
            field: t('USER_INFO.DOCUMENT_ID'),
            length: 6,
          }),
          minLength(6),
        ),
        maxLength: helpers.withMessage(
          t('USER_INFO.VALIDATIONS.MAX_LENGTH', {
            field: t('USER_INFO.DOCUMENT_ID'),
            length: 10,
          }),
          maxLength(10),
        ),
      },
    };
  },
);

const v$ = useVuelidate(rules, customer);

const cartActionsDisabled = computed(() => {
  const step = Object.values(collapseSteps.value)[currentStepIndex.value];

  return (
    currentStepIndex.value === 3
    || !step?.checked
    || v$.value.$invalid
    || !canContinue.value
  );
});

/*
 * Start animation slide
 */
function startAnimationSlide() {
  nextTick(() => {
    setTimeout(() => {
      showAnimationSlide.value = true;

      const allFieldsValid = Array.from(userFields.value.keys()).every(
        (key) => !!customer.value[key] && !v$.value[key].$invalid,
      );

      if (!allFieldsValid && userInfoComponent.value?.autoFocusFirstInput) {
        userInfoComponent.value.autoFocusFirstInput();
      }
    }, 100);
  });
}

onMounted(() => {
  startAnimationSlide();
});

function selectPaymentMethod(isCash: boolean) {
  createOrder(isCash ? PaymentMethod.CASH : PaymentMethod.CARD);
}

function nextStep() {
  const index = currentStepIndex.value;
  showAnimationSlide.value = false;
  if (index !== -1 && Object.values(collapseSteps.value)[index]) {
    Object.values(collapseSteps.value)[index].collapse = true;
    Object.values(collapseSteps.value)[index + 1].collapse = false;
    Object.values(collapseSteps.value)[index].checked = true;
  }
}

function previousStep() {
  const index = currentStepIndex.value;
  showAnimationSlide.value = false;
  if (index > 0) {
    Object.values(collapseSteps.value)[index].collapse = true;
    Object.values(collapseSteps.value)[index - 1].collapse = false;
  }
}

function goBack() {
  router.back();
  triggerGoBack(route.name);
}

onBeforeMount(() => {
  if (showCustomerView.value) {
    collapseSteps.value.customerInfo = {
      collapse: false,
      checked: true,
    };
  }

  if (tableController.displayTable && tableController.showTableInCheckout) {
    collapseSteps.value.tableNumber = {
      collapse: showCustomerView.value,
      checked: !showCustomerView.value,
    };
  }

  collapseSteps.value.payment = {
    checked: false,
    collapse: !!(collapseSteps.value.customerInfo || collapseSteps.value.tableNumber),
  };
});

onUnmounted(() => {
  hideSummary.value = true;
});
</script>

<template>
  <div class="flex flex-col bg-white full-hd">
    <section class="flex flex-col justify-between flex-grow overflow-auto">
      <div class="options-container">
        <CollapsableContainer
          v-if="collapseSteps.customerInfo"
          v-model="collapseSteps.customerInfo.collapse"
          :position="
            Object.keys(collapseSteps).findIndex((key) => key === 'customerInfo')
          "
          :title="t('CHECKOUT.CLIENT_INFO_TITLE')"
          :show-collapse="false"
          @finish-animation="startAnimationSlide()"
        >
          <OrderCheckoutUserInfoV2
            ref="userInfoComponent"
            :show-animation-slide="showAnimationSlide"
            v-model="customer"
            v-model:vuelidate="v$"
            :show-client-type="showClientType"
            :user-fields="userFields"
          />
        </CollapsableContainer>

        <template v-if="collapseSteps.tableNumber">
          <TableNumber
            v-model="collapseSteps.tableNumber.collapse"
            :position="
              Object.keys(collapseSteps).findIndex((key) => key === 'tableNumber')
            "
            @next="nextStep()"
          />
        </template>

        <!-- Cart summary -->
        <CollapsableContainer
          v-if="collapseSteps.payment"
          v-model="collapseSteps.payment.collapse"
          :position="Object.keys(collapseSteps).findIndex((key) => key === 'payment')"
          :title="t('CHECKOUT.SUMMARY_TITLE')"
          :show-collapse="false"
        >
          <Payment :customer="customer" />
        </CollapsableContainer>
      </div>
    </section>

    <div class="z-0 border-t border-gray-400">
      <CartActions
        :disabled="cartActionsDisabled"
        :action-lbl="$t('NEXT')"
        :show-cart-counter="false"
        @next="nextStep()"
        @back="goBack()"
      >
        <template #right-actions>
          <KioskButton
            @on-click="previousStep()"
            :disabled="currentStepIndex === 0"
            class="flex gap-5 !text-3xl !py-3.5"
            text-size="medium"
          >
            {{ $t('BACK') }}
          </KioskButton>
        </template>
      </CartActions>
    </div>
  </div>

  <OrderCheckoutModal
    v-if="paymentInProgress || jobResponse"
    :customer="customer"
    :order-total="orderTotal"
    @retry-cash="selectPaymentMethod(true)"
    @retry-card="selectPaymentMethod(false)"
  />
</template>

<style scoped>
.options-container {
  @apply flex flex-col my-12 z-20;
}

.options-container:last-child {
  @apply flex-grow;
}

.user-checkbox {
  @apply w-7 h-7 mr-4;
}

.invoice-border {
  @apply w-[810px] h-40 left-1/2 rounded-md flex items-center justify-center px-16 absolute z-0 -translate-x-1/2 -top-14;
  /* TODO: Check how make darker color */
  background-image: linear-gradient(to bottom, var(--kiosk-primary-color), #000);
}
</style>
