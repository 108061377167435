import { FulfillmentMode } from '@common/enums/FulfillmentMode';
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { IOrderOption } from '@/modules/order-type/types';
import KfcBucketUrl from '@/assets/images/kfc-bucket.png?url';
import KfcBagUrl from '@/assets/images/kfc-bag.png?url';

export const useOrderTypeStore = defineStore('orderType', () => {
  const { t } = useI18n();

  // State
  const orderTypes = ref<IOrderOption[]>([
    {
      icon: 'eat-here',
      label: 'EAT_HERE',
      value: FulfillmentMode.DINE_IN,
      alternativeKfcImg: KfcBucketUrl,
    },
    {
      icon: 'shopping-bag',
      label: 'TAKE_AWAY',
      value: FulfillmentMode.PICKUP,
      alternativeKfcImg: KfcBagUrl,
    },
  ]);

  const createOrderType = ref<PaymentMethod>(PaymentMethod.UNKNOWN);

  const fulfillmentMode = ref<FulfillmentMode>(FulfillmentMode.DINE_IN);

  const orderOptions = computed(() =>
    orderTypes.value.map((type) => ({
      ...type,
      label: type.label,
    })));

  function clearOrderType() {
    fulfillmentMode.value = FulfillmentMode.DINE_IN;
    orderTypes.value = [
      {
        icon: 'eat-here',
        label: 'EAT_HERE',
        value: FulfillmentMode.DINE_IN,
        alternativeKfcImg: KfcBucketUrl,
      },
      {
        icon: 'shopping-bag',
        label: 'TAKE_AWAY',
        value: FulfillmentMode.PICKUP,
        alternativeKfcImg: KfcBagUrl,
      },
    ];
  }

  function setCreateOrderType(type: PaymentMethod) {
    createOrderType.value = type;
  }

  function orderType(tableNumber: string) {
    if (fulfillmentMode.value === FulfillmentMode.PICKUP) {
      return tableNumber ? `${t('TAKE_AWAY')} - ${t('TABLE')} ${tableNumber}` : t('TAKE_AWAY');
    }

    return tableNumber
      ? `${t('EAT_HERE')} - ${t('TABLE')} ${tableNumber}`
      : t('EAT_HERE');
  }

  return {
    orderTypes,
    orderOptions,
    createOrderType,
    orderType,
    fulfillmentMode,
    clearOrderType,
    setCreateOrderType,
  };
});
