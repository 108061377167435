<script lang="ts" setup>
import { currencies } from '@slabcode/kiosks-core/utils';
import { Currencies } from '@slabcode/kiosks-core/enums';

defineProps({
  showBack: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// Emit events
defineEmits<{
  save: [];
  back: [];
}>();

const metadataStoreV2 = useMetadataStoreV2();
const cart = useCartStoreV2();
const { subtotal: total } = storeToRefs(cart);
const { clusterSettings } = storeToRefs(metadataStoreV2);
const tipsController = useTipsController();
const { calculatedTip } = storeToRefs(tipsController);
const currencyController = useCurrencyController();
const { currencyCode } = storeToRefs(currencyController);

function getFlag(code: Currencies) {
  if (code in currencies) {
    return currencies[code].flag;
  }
  return null;
}

const currenciesTotals = computed(() => {
  if (!clusterSettings.value?.currency) {
    console.error('Currency settings not found');
    return [];
  }

  const availableCurrencies = [];

  availableCurrencies.push({
    flag: getFlag(clusterSettings.value.currency.preferredCurrencyCode),
    code: clusterSettings.value.currency.preferredCurrencyCode,
    total: (calculatedTip.value + total.value).toFixed(2),
  });

  if (!clusterSettings.value.currency.conversions) {
    return availableCurrencies;
  }

  const conversions = clusterSettings.value.currency.conversions.map((conversion) => {
    const convertedTotal = (
      (calculatedTip.value + total.value)
      * conversion.rate
    ).toFixed(2);

    return {
      flag: getFlag(conversion.code),
      code: conversion.code,
      total: convertedTotal,
    };
  });

  return [...availableCurrencies, ...conversions];
});
</script>

<template>
  <div class="flex flex-col items-center justify-center w-full h-full gap-11">
    <section class="flex flex-col items-center justify-center h-full gap-8">
      <header class="flex flex-col items-center gap-3 text-center max-w-[510px]">
        <h2 class="text-[40px] !font-medium text-center leading-10 uppercase">
          {{ $t('CURRENCY.MODAL_TITLE') }}
        </h2>
        <p class="text-3xl text-center text-gray-600">
          {{ $t('CURRENCY.MODAL_DESCRIPTION') }}
        </p>
      </header>
      <article class="grid grid-cols-2 gap-8">
        <template v-for="conversion in currenciesTotals" :key="conversion">
          <label
            :class="[
              'lbl-bg',
              currencyCode === conversion.code
                ? 'border-kiosk-btnPrimaryBg shadow-lg'
                : 'border-[#D2CFCF]',
            ]"
          >
            <input
              type="radio"
              v-model="currencyCode"
              class="absolute inset-0 opacity-0 cursor-pointer"
              :value="conversion.code"
            />
            <div class="items-center justify-center w-full h-auto text-center">
              <div class="flex items-center justify-center w-[68px] h-[68px] mx-auto">
                <img
                  v-if="conversion.flag"
                  class="w-10 h-10 rounded-full"
                  :src="conversion.flag"
                  :alt="conversion.code"
                />
              </div>
              <p
                :class="{
                  'font-medium': currencyCode === conversion.code,
                  'text-3xl': true,
                }"
              >
                $ {{ conversion.total }}
              </p>
              <p class="mt-1 text-xl text-muted">{{ conversion.code }}</p>
            </div>
          </label>
        </template>
      </article>
    </section>
    <footer :class="['flex items-end w-full gap-8', !showBack ? 'justify-center' : '']">
      <KioskButton
        v-if="showBack"
        @on-click="$emit('back')"
        class="!w-full"
        text-size="small"
      >
        {{ $t('CURRENCY.BACK') }}
      </KioskButton>
      <KioskButton
        @on-click="$emit('save')"
        color="primary"
        text-size="small"
        :class="showBack ? '!w-full' : '!w-[220px]'"
      >
        {{ $t('PAY') }}
      </KioskButton>
    </footer>
  </div>
</template>

<style scoped>
.lbl-bg {
  @apply bg-[linear-gradient(129deg,#F8F8F8_2.02%,#F0F0F0_46.38%,#F8F8F8_94.44%)] transition-all relative;
  @apply flex items-center justify-center w-[204px] h-[204px] py-[30px] px-[40px] border-2 rounded-cards cursor-pointer;
}
</style>
