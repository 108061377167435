import { RedeemConditionTypes } from '@slabcode/kiosks-core/enums';

import { useI18n } from 'vue-i18n';

import { RouteRecordNameGeneric } from 'vue-router';
import { CouponResponse,
  CouponResponseType,
  CouponValidation } from '../interfaces';
import { ReservedCoupon } from '../interfaces/reservedCoupon';

export const useCouponStore = defineStore('coupon', () => {
  const route = useRoute();
  const router = useRouter();

  const { storeId, integration } = useKioskInfoStore();
  const languageStore = useLanguageStore();
  const cartStore = useCartStoreV2();
  const orderTypeStore = useOrderTypeStore();
  const invoiceCouponStore = useInvoiceCouponStore();
  const productCouponStore = useProductCouponStore();
  const metadataStoreV2 = useMetadataStoreV2();

  const errorStore = useErrorStore();
  const { t } = useI18n();

  // This is use to know from which route the user navigated to the coupon view to metadata configs
  const fromRoute = ref<RouteRecordNameGeneric>();
  const promoCode = ref('');
  const loading = ref(false);
  const reservedCoupon = ref<ReservedCoupon | null>(null);
  const errorInfo = ref<{ cardTitle: string; cardDescription: string } | null>(null);

  const activeCoupon = computed(() => {
    const activeLabels = {
      title: t('COUPON.COUPON_ALREADY_REDEEMED'),
      description: t('COUPON.IF_YOU_WANT_DELETE_FIRST'),
    };

    if (productCouponStore.couponViewInfo) {
      return {
        ...productCouponStore.couponViewInfo,
        ...activeLabels,
      };
    }

    if (invoiceCouponStore.couponViewInfo) {
      return {
        ...invoiceCouponStore.couponViewInfo,
        ...activeLabels,
      };
    }

    return undefined;
  });

  const requestIsFinished = computed(
    () =>
      !loading.value
      && (Boolean(reservedCoupon.value) || Boolean(errorInfo.value)),
  );

  const isVisible = computed(() => {
    if (route.name === RouteName.COUPON) return true;
    if (!metadataStoreV2.kioskSettings?.coupons) return false;
    const { cartView, mainView } = metadataStoreV2.kioskSettings.coupons;

    if (cartView.enabled && route.name === RouteName.CART) return true;
    if (mainView.enabled && route.name === RouteName.PRODUCTS) return true;
    return false;
  });

  const showQrScanner = computed(() => {
    if (!metadataStoreV2.kioskSettings?.coupons) return false;
    const { cartView, mainView } = metadataStoreV2.kioskSettings.coupons;
    if (cartView.settings.qrScanner && fromRoute.value === RouteName.CART) return true;
    if (mainView.settings.qrScanner && fromRoute.value === RouteName.PRODUCTS) return true;
    return false;
  });

  const showTextInput = computed(() => {
    if (!metadataStoreV2.kioskSettings?.coupons) return false;
    const { cartView, mainView } = metadataStoreV2.kioskSettings.coupons;
    if (cartView.settings.text && fromRoute.value === RouteName.CART) return true;
    if (mainView.settings.text && fromRoute.value === RouteName.PRODUCTS) return true;
    return false;
  });

  /**
   * Validate Coupon
   * @param promoCode string
   */
  async function validate() {
    loading.value = true;
    const validationObj: CouponValidation = {
      storeId,
      integrationName: integration,
      promoCode: promoCode.value,
      orderValues: {
        timestamp: new Date().toLocaleString('sv'),
        language: languageStore.currentLanguage.toUpperCase(),
        orderInfo: {
          itemsQuantity: cartStore.itemsCount,
          fulfillmentMode: orderTypeStore.fulfillmentMode,
          orderTotal: {
            subtotal: cartStore.subtotal - cartStore.taxes,
            tax: cartStore.taxes,
          },
        },
      },
    };

    try {
      const res: CouponResponse = await couponRequests.post(
        '/coupons/validate',
        validationObj,
      );
      return res;
    } catch (error: unknown) {
      return null;
    } finally {
      loading.value = false;
    }
  }

  async function validatePromoCode() {
    const res = await validate();

    errorInfo.value = null;
    productCouponStore.reset();
    invoiceCouponStore.reset();

    if (!res || res.result === CouponResponseType.INVALID || !res.contents) {
      errorInfo.value = {
        cardTitle: res?.message.title ?? t('COUPON.COUPON_NOT_AVAILABLE'),
        cardDescription:
          res?.message.description ?? t('COUPON.LOOKS_IS_NOT_AVAILABLE'),
      };
      return;
    }

    reservedCoupon.value = res.contents.reservedCoupon;
    productCouponStore.setup(res);
    invoiceCouponStore.setup(res);
  }

  const isCouponValid = computed(() => {
    if (!reservedCoupon.value) return false;
    const { conditions } = reservedCoupon.value;
    const { type, value } = conditions;

    if (type === RedeemConditionTypes.Price) {
      return cartStore.totalWithTaxes >= value;
    }

    if (type === RedeemConditionTypes.Quantity) {
      return cartStore.itemsCountWithoutCoupon >= value;
    }

    return false;
  });

  function reset() {
    productCouponStore?.reset?.();
    invoiceCouponStore?.reset?.();
    promoCode.value = '';
    loading.value = false;
    reservedCoupon.value = null;
    errorInfo.value = null;
  }

  // TODO: Validar error cuando se elimina en cart
  async function clearCoupon() {
    if (!reservedCoupon.value) return;
    loading.value = true;

    try {
      await couponRequests.post('/coupons/cancel', {
        storeId,
        couponId: reservedCoupon.value.id,
        taskId: reservedCoupon.value.taskId,
      });

      errorInfo.value = {
        cardDescription: t('COUPON.DELETED_SUGGESTION'),
        cardTitle: t('COUPON.COUPON_DELETED'),
      };
      reset();
    } catch (error: unknown) {
      errorInfo.value = {
        cardDescription: t('COUPON.NOT_DELETED_SUGGESTION'),
        cardTitle: t('COUPON.COUPON_COULD_NOT_BE_DELETED'),
      };
      errorStore.handleError(error);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Revalidate Coupon
   *  @param couponId number
   */
  async function revalidateCoupon() {
    // clear the coupon if it is not valid
    if (!isCouponValid.value) {
      reset();
      await clearCoupon();
    }
  }

  function navigateToCoupons() {
    fromRoute.value = route.name;

    // Show active coupon view if there is an active coupon
    if (activeCoupon.value) {
      router.push({ name: RouteName.COUPON_ACTIVE });
      return;
    }

    router.push({ name: RouteName.COUPON });
  }

  return {
    // ACTIONS
    reset,
    clearCoupon,
    revalidateCoupon,
    validatePromoCode,
    navigateToCoupons,
    isCouponValid,

    // STATE
    loading,
    isVisible,
    errorInfo,
    promoCode,
    showQrScanner,
    showTextInput,
    reservedCoupon,
    requestIsFinished,
    activeCoupon,
  };
});
