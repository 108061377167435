import { TipsStyles } from '../enums/tipsStyles';

export const useTipsController = defineStore('tips', () => {
  const metadataStoreV2 = useMetadataStoreV2();
  const { clusterSettings } = storeToRefs(metadataStoreV2);
  const cartStoreV2 = useCartStoreV2();

  const tipSelected = ref<number>(0);

  function calculateTip(tip: number) {
    const { discount } = cartStoreV2.orderTotal();
    const totalWithDiscounts = cartStoreV2.subtotal - (discount ?? 0);
    // NOTE: If the total is 0, we should use the subtotal to calculate the tip to have a tip different than 0
    const total = totalWithDiscounts === 0 ? cartStoreV2.subtotal : totalWithDiscounts;
    return (total * (tip / 100)).toFixed(2);
  }

  const calculatedTip = computed(() => parseFloat(calculateTip(tipSelected.value)));

  const tips = computed(() => {
    const amounts = clusterSettings.value?.tips?.amounts;
    return amounts ? [amounts.low, amounts.medium, amounts.high, amounts.excellent] : [];
  });
  const hasGamification = computed(() => clusterSettings.value?.tips?.style === TipsStyles.Gamified);
  const hasTipModal = computed(() => clusterSettings.value?.tips?.enabled && tips.value.length > 0);

  function clear() {
    tipSelected.value = 0;
  }

  return {
    tipSelected,
    tips,
    calculatedTip,
    calculateTip,
    hasGamification,
    hasTipModal,
    clear,
  };
});
