<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { type Swiper as ISwiper } from 'swiper/types';
import { Pagination } from 'swiper/modules';

import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';

const emit = defineEmits<{
  closeModal: [];
}>();

const orderTypeStore = useOrderTypeStore();
const createOrderUtils = useCreateOrder();
const { createOrderType: paymentMethod } = storeToRefs(orderTypeStore);
const tipsController = useTipsController();
const currencyController = useCurrencyController();

const hasTipModal = computed(() => Boolean(tipsController.hasTipModal));
const hasCurrencyModal = computed(() => Boolean(currencyController.hasCurrencyModal) && paymentMethod.value === PaymentMethod.CARD);
const calculatedViewsLength = computed(() => Number(hasCurrencyModal.value) + Number(hasTipModal.value));

const swiperRef = ref<ISwiper | null>(null);

const currentStep = computed(() => {
  if (!swiperRef.value) return 1;
  return swiperRef.value.activeIndex + 1;
});

function onSwiper(swiper: ISwiper) {
  swiperRef.value = swiper;
}

function back() {
  if (!swiperRef.value) return;
  swiperRef.value.slidePrev();
}

function next() {
  if (!swiperRef.value) return;
  swiperRef.value.slideNext();
}

onMounted(() => {
  if (calculatedViewsLength.value === 0) {
    emit('closeModal');
    createOrderUtils.createOrder(paymentMethod.value);
  }
});

function createOrContinue() {
  if (currentStep.value === calculatedViewsLength.value) {
    emit('closeModal');
    createOrderUtils.createOrder(paymentMethod.value);
    return;
  }

  next();
}
</script>

<template>
  <KioskModal>
    <template #default>
      <p class="pt-2 pb-8 text-3xl text-center" v-if="calculatedViewsLength > 1">
        {{ $t('STEP') }} {{ currentStep }}/{{ calculatedViewsLength }}
      </p>
      <Swiper
        :speed="800"
        :pagination="{
          type: 'custom',
        }"
        :grab-cursor="false"
        :allow-touch-move="false"
        :modules="[Pagination]"
        :space-between="32"
        @pagination-update="onSwiper($event)"
        @swiper="onSwiper($event)"
        class="w-full custom-swiper"
      >
        <swiper-slide v-if="hasTipModal">
          <TipsModal
            :disabled-left-button="currentStep == 1"
            :secondary-lbl-action="
              calculatedViewsLength > 1
                ? $t('TIPS.OMIT_AND_CONTINUE')
                : $t('TIPS.OMIT_AND_PAY')
            "
            :main-lbl-action="
              calculatedViewsLength > 1
                ? $t('TIPS.ADD_AND_CONTINUE')
                : $t('TIPS.ADD_AND_PAY')
            "
            @save="createOrContinue()"
          />
        </swiper-slide>
        <swiper-slide v-if="hasCurrencyModal">
          <CurrencyModal
            :show-back="calculatedViewsLength > 1"
            @save="createOrContinue()"
            @back="back()"
          />
        </swiper-slide>
      </Swiper>
    </template>
    <template #close-button>
      <KioskButton
        class="!rounded-full !border-0 !w-[80px] !h-[80px]"
        @on-click="$emit('closeModal')"
      >
        <span class="text-current icon icon-clear" />
      </KioskButton>
    </template>
  </KioskModal>
</template>
