/* eslint-disable no-unused-vars */
export const RouteName = {
  WRAPPER: 'OrderWrapper',
  STORE: 'Store',
  STAND_BY_BANNER: 'StandbyBanner',
  MAINTENANCE: 'maintenance',
  NOT_FOUND: 'not-found',
  SOMETHING_WENT_WRONG: 'something-went-wrong',
  ORDERS: 'Orders',
  WELCOME: 'WelcomeView',
  ORDER_OPTIONS: 'OrderOptions',
  ORDER_TYPE: 'OrderTypeView',
  FULFILLMENT: 'FulfillmentView',
  ORDER_CATEGORIES: 'OrderCategoriesView',
  PRODUCTS: 'OrderProductsView',
  STEP_CUSTOMIZE: 'StepCustomize',
  DROPDOWN_CUSTOMIZE: 'DropdownCustomize',
  VERTICAL_CUSTOMIZE: 'VerticalStepCustomize',
  CART: 'CartV2',
  CHECKOUT: 'CheckoutV2',
  TABLE: 'Table',
  COUPON: 'Coupon',
  COUPON_VALIDATION: 'CouponValidation',
  COUPON_ACTIVE: 'CouponActive',
};
