<script lang="ts" setup>
const cartStore = useCartStoreV2();

defineProps({
  actionLbl: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  showMainAction: {
    type: Boolean,
    default: true,
  },
  showCartCounter: {
    type: Boolean,
    default: true,
  },
});

defineEmits(['back', 'next']);

</script>

<template>
  <KioskFooter
    v-bind="$attrs"
    @back="$emit('back')"
  >
    <div class="flex items-center gap-6">
      <button
        type="button"
        class="footer-back-btn"
        @click="$emit('back')"
      >
        <span class="text-4xl icon icon-home" />
        <span class="text-xl font-light">{{ $t('GO_BACK') }}</span>
      </button>
      <slot name="left-actions" />
    </div>
    <div class="flex items-center gap-10">
      <span class="text-4xl">
        <slot name="price" />
      </span>
      <slot name="right-actions" />
      <KioskButton
        v-if="showMainAction"
        :disabled="disabled"
        @click="$emit('next')"
        class="checkout-button"
        text-size="medium"
        color="primary"
      >
        {{ actionLbl }}
        <span v-if="showCartCounter" class="cart-counter">{{ cartStore.itemsCount }}</span>
      </KioskButton>
    </div>
  </KioskFooter>
</template>

<style scoped>
.bottom-actions {
  @apply bg-white flex justify-between items-center gap-10 w-full h-full px-8 py-3 z-20 relative;
  box-shadow: #0000009e -4px 4px 17px 0px;
}

.checkout-button {
  @apply flex gap-5 text-3xl py-3.5;
}

.cart-button {
  @apply relative text-5xl text-black uppercase transition-all disabled:opacity-70 h-max btn btn-ghost disabled:bg-transparent;
}

.cart-counter {
  @apply w-9 h-9 grid place-items-center text-lg font-bold rounded-full bg-white text-kiosk-primary;
}
</style>
