<script lang="ts" setup>
import { RouteName } from '@common/routes/routeName';

const router = useRouter();
const metadataStoreV2 = useMetadataStoreV2();
const { priceDecimals } = storeToRefs(metadataStoreV2);

const menuStore = useMenuStore();
const { productsCurrency } = storeToRefs(menuStore);

const cartStoreV2 = useCartStoreV2();
const { items, subtotal } = storeToRefs(cartStoreV2);

const { getFormatPrice } = useTotal();
const { continueCustomization } = useCustomize();
const { triggerViewCart, triggerGoBack } = useGTMEventsComposableV2();

const productCouponStore = useProductCouponStore();
const couponStore = useCouponStore();
const route = useRoute();
const { decreaseCartItemQuantity, increaseCartItemQuantity, removeItem } = useCartItemHandler();

function goBack() {
  router.push({ name: RouteName.PRODUCTS });
  triggerGoBack(route.name);
}

onBeforeMount(() => {
  triggerViewCart(items.value, subtotal.value);
});
</script>

<template>
  <section class="flex flex-col justify-between bg-white full-hd">
    <section
      class="h-full pb-32 overflow-auto animate__animated animate__fadeInLeft"
    >
      <UpSellingBanners />

      <NotCartItems v-if="items.length === 0 && !productCouponStore.productCoupon" />
      <div class="px-10 py-5" v-else>
        <TransitionGroup name="add-animation">
          <ShoppingCouponProduct
            v-if="productCouponStore.productCoupon"
            :name="productCouponStore.productCoupon.item.name"
            :description="productCouponStore.productCoupon.item.description"
            :original-price="productCouponStore.originalItemPrice"
            :discount-price="productCouponStore.discountPrice"
            :image="productCouponStore.productCoupon.item.photoIds[0]"
            @delete="couponStore.clearCoupon()"
          />
          <ShoppingCartItemV2
            v-for="(item, i) in items"
            :key="item.item.itemMeta.id"
            :item="item.item"
            :description="item.description"
            :currency="productsCurrency"
            :price-decimals="priceDecimals"
            @add="increaseCartItemQuantity(item.item, i)"
            @sub="decreaseCartItemQuantity(item.item, i)"
            @delete="removeItem(item.item, i)"
            @edit="continueCustomization($event, i)"
          />
        </TransitionGroup>
      </div>
    </section>

    <div class="border-t border-gray-400">
      <FooterActionsCoupon
        :action-lbl="$t('PAY')"
        @next="router.push({ name: RouteName.CHECKOUT })"
        @back="goBack()"
        :disabled="items.length === 0 && !productCouponStore.productCoupon"
        :price="getFormatPrice(cartStoreV2.subtotal)"
      />
    </div>
  </section>
</template>

<style scoped>
.cart-amount {
  @apply relative p-3 h-[92px] w-full text-3xl flex items-center justify-center text-white;
}

.back-button {
  @apply absolute left-5 top-1/2 -translate-y-1/2 w-10 h-10;
}

.text-separator {
  @apply border-r h-10 border-white mx-8 bg-white;
}

.add-animation-enter-active {
  animation: add-slowly 0.5s both;
}
.add-animation-leave-active {
  animation: add-slowly 0.5s both reverse;
}

@keyframes add-slowly {
  from {
    opacity: 0;
    transform: translateY(-70%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
